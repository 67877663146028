// customize some Bootstrap variables
$primary: darken(#428bca, 20%);
$btn-hover-bg-shade-amount: 40%;
$btn-hover-bg-tint-amount: 50%;
$input-btn-focus-blur: 5px;

@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";

// Amend some Bootstrap variables
$form-select-focus-box-shadow:    0 0 $input-btn-focus-blur $form-select-focus-width $input-btn-focus-color;


// the ~ allows you to reference things in node_modules
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import "~bootstrap/scss/bootstrap";

body {
    background-color: lightgray;
}
